// store.js
import { writable } from "svelte/store";
export const initVal = {
    vinyl: false,
    width: null,
    depth: null,
    height: null,
    pressure: "Visco",
    backrest: "Waterfall",
    lateralSupport: "None",
    dropdownArmrest: false,
    profiledHeadrest: false,
    selectorView: null,
};
export const configStore = writable(initVal);
