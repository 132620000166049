// store.js
import { writable } from "svelte/store";
export const initVal = {
    lever: false,
    bumpers: false,
    liftingPole: false,
    pumpHolder: false,
    safetyMat: false,
    sideRails: false,
};
export const configStore = writable(initVal);
