export const bedVariants = {
    wooden: {
        Arc: [
            { title: "Vicenza Oak", options: { quickship: true } },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Atelier: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Bento: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Chateau: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Classic: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Skandi: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        Vogue: [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
        "Custom Arc": [
            { title: "Vicenza Oak", options: {} },
            { title: "Natural Lancaster Oak", options: {} },
            { title: "Lissa Oak", options: {} },
            { title: "Tobacco Gladstone Oak", options: {} },
            { title: "Black Brown Thermo Oak", options: {} },
            { title: "Grey Beige Tossini Elm", options: {} },
            { title: "Brown Tossini Elm", options: {} },
            { title: "Natural Dijon Walnut", options: {} },
            { title: "Standard Walnut", options: {} },
            { title: "Lincoln Walnut", options: {} },
            { title: "Polar Aland Pine", options: {} },
            { title: "Bavarian Beech", options: {} },
            { title: "Grey Sacramento Oak", options: {} },
            { title: "Brown Tonsberg Oak", options: {} },
            { title: "Anthracite Fineline Metallic", options: {} },
            { title: "Sand Orleans Oak", options: {} },
        ],
    },
    fabric: {
        Alexander: [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
        ],
        "Alexander with Wood": [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
        ],
        Belgrave: [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
        ],
        Cleveland: [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
        ],
        Finsbury: [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
        ],
        Grosvenor: [
            { title: "Silver", options: {} },
            { title: "Hessian", options: {} },
            { title: "Steel", options: {} },
            { title: "Dove", options: {} },
            { title: "Chablis", options: {} },
            { title: "Sky", options: {} },
            { title: "Ocean", options: {} },
            { title: "Fern Green", options: {} },
            { title: "Lilac", options: {} },
            { title: "Cyclamen", options: {} },
            { title: "Royal", options: {} },
            { title: "Teal", options: {} },
            { title: "Peony", options: {} },
            { title: "Gold", options: {} },
            { title: "Fig", options: {} },
            { title: "Mushroom", options: {} },
            { title: "Pebble Tones", options: {} },
        ],
    }
};
