export const isSidePanelAllowed = (state) => {
    if (state.variant === "ARC") {
        return false;
    }
    return true;
};
export const sidePanelExceptionReason = (state) => {
    if (state.variant === "ARC") {
        return "Not available for ARC";
    }
    return null;
};
