// store.js
import { writable } from "svelte/store";
;
;
;
;
export const initVal = {
    sideRails: "None",
    bumpers: false,
    safetyMat: "None",
    safetySleeve: false,
    juniorKit: false,
    lever: "None",
    liftingPole: false,
    pumpHolder: false,
    mattressInfill: false,
    bedExtension: false,
    mattressExtensionFoam: false,
    bedExtensionKit: false,
    selectorView: null,
};
export const configStore = writable(initVal);
