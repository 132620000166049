export const colorsOrder = [
    "Vicenza Oak",
    "Natural Lancaster Oak",
    "Lissa Oak",
    "Tobacco Gladstone Oak",
    "Black Brown Thermo Oak",
    "Grey Beige Tossini Elm",
    "Brown Tossini Elm",
    "Natural Dijon Walnut",
    "Standard Walnut",
    "Lincoln Walnut",
    "Polar Aland Pine",
    "Bavarian Beech",
    "Grey Sacramento Oak",
    "Brown Tonsberg Oak",
    "Anthracite Fineline Metallic",
    "Sand Orleans Oak",
];
