export const colorsOrder = [
    "Natural Lancaster Oak",
    "Locarno Cherry",
    "Natural Dijon Walnut",
    "Anthracite Fineline Metallic",
    "Bavarian Beech",
    "Black Brown Thermo Oak",
    "Brown Tonsberg Oak",
    "Brown Tossini Elm",
    "Grey Sacramento Oak",
    "Grey Beige Tossini Elm",
    "Lincoln Walnut",
    "Polar Aland Pine",
    "Sand Orleans Oak",
    "Tobacco Gladstone Oak",
    "Vicenza Oak",
];
