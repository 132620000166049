export const fabricsOrder = [
    "Silver",
    "Hessian",
    "Steel",
    "Dove",
    "Chablis",
    "Sky",
    "Ocean",
    "Fern Green",
    "Lilac",
    "Cyclamen",
    "Royal",
    "Teal",
    "Peony",
    "Gold",
    "Fig",
    "Mushroom",
    "Pebble Tones",
];
