// store.js
import { writable } from "svelte/store";
export const initVal = {
    variant: "Arc",
    color: "Vicenza Oak",
    fabric: "Silver",
    sidePanel: "No Side Panels",
    selectorView: null,
};
export const configStore = writable(initVal);
