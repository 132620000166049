export const bedVariants = {
    ARC: [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        {
            title: "Locarno Cherry",
            options: {
                quickship: true,
            },
        },
        { title: "Natural Dijon Walnut", options: { quickship: true } },
        { title: "Natural Lancaster Oak", options: { quickship: true } },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    "Custom ARC": [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        {
            title: "Locarno Cherry",
            options: {},
        },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Atelier: [
        { title: "Bavarian Beech", options: {} },
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        { title: "Locarno Cherry", options: {} },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Bento: [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        { title: "Locarno Cherry", options: {} },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Chateau: [
        { title: "Bavarian Beech", options: {} },
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        { title: "Locarno Cherry", options: {} },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Classic: [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        { title: "Locarno Cherry", options: {} },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Skandi: [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        {
            title: "Locarno Cherry",
            options: {
                quickship: true,
            },
        },
        { title: "Natural Dijon Walnut", options: { quickship: true } },
        { title: "Natural Lancaster Oak", options: { quickship: true } },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
    Vogue: [
        { title: "Anthracite Fineline Metallic", options: {} },
        { title: "Bavarian Beech", options: {} },
        { title: "Black Brown Thermo Oak", options: {} },
        { title: "Brown Tonsberg Oak", options: {} },
        { title: "Brown Tossini Elm", options: {} },
        { title: "Grey Sacramento Oak", options: {} },
        { title: "Grey Beige Tossini Elm", options: {} },
        { title: "Lincoln Walnut", options: {} },
        { title: "Locarno Cherry", options: {} },
        { title: "Natural Dijon Walnut", options: {} },
        { title: "Natural Lancaster Oak", options: {} },
        { title: "Polar Aland Pine", options: {} },
        { title: "Sand Orleans Oak", options: {} },
        { title: "Tobacco Gladstone Oak", options: {} },
        { title: "Vicenza Oak", options: {} },
    ],
};
