// store.js
import { writable } from "svelte/store";
export const initVal = {
    variant: "Arc",
    color: "Vicenza Oak",
    fabric: "Silver",
    sidePanel: "No Side Panels",
    liftingPole: "Not included",
    safetyMat: "Not included",
    assistBar: "None",
    proTectSideRail: "Not included",
    fabricSideRails: "Not included",
    foldingSideRails: "Not included",
    foldingSideRailsWithBumper: "Not included",
    widthAdjustmentKit: "Not included",
    bedWallBumper: "Not included",
    selectorView: null,
};
export const configStore = writable(initVal);
