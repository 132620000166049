// store.js
import { writable } from "svelte/store";
export const initVal = {
    variant: "Skandi",
    color: "Natural Lancaster Oak",
    sidePanel: "Included",
    liftingPole: "Not included",
    safetyMat: "Not included",
    assistBar: "None",
    selectorView: null,
};
export const configStore = writable(initVal);
